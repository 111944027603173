<template>
  <a-drawer
    :title="dicName"
    :visible="visible"
    :confirm-loading="confirmLoading"
    :width="450"
    :body-style="{ paddingBottom: '80px' }"
    @close="handleCancel"
    destroyOnClose
  >
  <a-row class="wrapper" type="flex" :gutter="16">
    <a-col :span="24">
      <div class="antd-panel">
        <div class="tool-tip">
          <a-button class="ant-btn ant-btn-primary" @click="createWikiDicEvt()" v-if="fromType!=2" v-permit:manageWiki="{moduleId:17}">+ 新建目录
          </a-button>
          <br /><br />
          <a-input-search placeholder="请输入" @change="onSearch" />
        </div>
        <!-- 新增目录弹窗 -->
        <add-form v-if="addFormVisible" ref="addForm" @refreshDataList="loadTreeData"></add-form>
        <!-- 修改目录弹窗 -->
        <update-form v-if="updateFormVisible" ref="updateForm" @refreshDataList="loadTreeData"></update-form>

        <a-tree :show-line="showLine" :tree-data="treeData" :expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent" @expand="onExpand">
          <template slot="custom" slot-scope="item">
            <span @mouseover="handleOver(item)" @mouseout="handleOut(item)">
              <span v-if="item.title.indexOf(searchValue) > -1" @click="onSelectNode(item)">
                {{item.title.substr(0, item.title.indexOf(searchValue)) }}
                <span style="color: #f50">{{searchValue}}</span>
                {{item.title.substr(item.title.indexOf(searchValue) + searchValue.length)}}
              </span>
              <span v-else @click="onSelectNode(item)">{{item.title}}</span>
              <span v-show="item.isShow" class="tree-node-handle" v-if="fromType!=2" v-permit:manageWiki="{moduleId:17}">
                <a-tooltip placement="bottom" title="新增子菜单">
                  <a-icon type="plus-circle-o" @click="createSubMenuEvt(item)" />
                </a-tooltip>
                <a-tooltip placement="bottom" title="修改菜单">
                  <a-icon type="edit" @click="updateWikiDicEvt(item)" />
                </a-tooltip>
                <a-tooltip placement="bottom" title="删除菜单">
                  <a-icon type="minus-circle-o" @click="deleteWikiDicEvt(item)" />
                </a-tooltip>
              </span>
            </span>
          </template>
        </a-tree>
      </div>
    </a-col>
  </a-row>
  </a-drawer>

</template>

<script>
import { detailKonwList,delDetailKonwList,getDetailList,updateDetailKonwList } from "@/httpsAPI/knowledgeBase/index";
import AddForm from './add'
import UpdateForm from './update'

const treeData = [];
const getParentKey = (title, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.title === title)) {
        parentKey = node.key;
      } else if (getParentKey(title, node.children)) {
        parentKey = getParentKey(title, node.children);
      }
    }
  }

  return parentKey;
};
const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const key = node.key;
    dataList.push({ key, title: node.title });
    if (node.children) {
      generateList(node.children);
    }
  }
};

export default {
  data () {
    return {
      form: this.$form.createForm(this),
      visible: false,
      confirmLoading: false,
      showLine: true,
      expandedKeys: [],
      searchValue: '',
      autoExpandParent: true,
      treeData,
      wikiId: '',
      wikiDic: {},
      isUpdate: false,
      selectedRows: [],
      addFormVisible: false,
      updateFormVisible: false,
      selWikiDicId: 0,
      parentId: 0,
      content:null,
      updateEvt:false,
      accessoryVisible: false,
      issShowType:true,
      dicName: '',
      fromType: 0
    }
  },
  components: {
    AddForm,
    UpdateForm,
  },
  methods: {
    // 新增一级目录
    createWikiDicEvt () {
      this.addFormVisible = true
      this.$nextTick(() => {
        this.$refs.addForm.init({ wikiId: this.wikiId, parentId: this.parentId })
      })
    },
    // 新建子菜单
    createSubMenuEvt (item) {
      this.addFormVisible = true
      this.$nextTick(() => {
        this.$refs.addForm.init({ wikiId: this.wikiId, parentId: item.key })
      })
    },
    // 修改目录
    updateWikiDicEvt (item) {
      this.updateFormVisible = true
      this.$nextTick(() => {
        this.$refs.updateForm.init(item.key)
      })
    },
    // 删除目录
    deleteWikiDicEvt(item) {
        const that = this
        this.$confirm({
            title: '系统提示',
            content: '确认删除此菜单[' + item.title + ']?',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                delDetailKonwList([item.key]).then(res => {
                    that.$message.success({
                        content: '操作成功',
                        duration: 1,
                        onClose: () => {
                            that.loadTreeData()
                        }
                    })
                }).catch(error => {
                    console.log(error)
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    },
    handleOver (item) {
      item.dataRef.isShow = true
    },
    handleOut (item) {
      item.dataRef.isShow = false
    },
    // 选择树形节点
    onSelectNode (item) {
      // 跳转详情 fromType:0我的知识库，1公共知识库，2我的收藏
      if(this.fromType==1) {
        this.$router.push({ path: '/knowledge/base/detail',query:{wikiId:this.wikiId} })
      }

      this.updateEvt = true
      this.selWikiDicId = item.key
      this.loadBtnData()
    },
    filterTreeData (arr) {
      arr.map(t => {
        if (t.children && t.children.length) {
          this.filterTreeData(t.children)
        }
        return this.$set(t, 'isShow', false)
      })
    },
    onSearch (e) {
      const value = e.target.value;
      const expandedKeys = dataList
        .map(item => {
          if (item.title.indexOf(value) > -1 && value != '') {
            return getParentKey(item.title, this.treeData);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      Object.assign(this, {
        expandedKeys,
        searchValue: value,
        autoExpandParent: true,
      });
    },
    onExpand (expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    loadTreeData () {
      detailKonwList({ wikiId: this.wikiId }).then(res => {
        this.treeData = res.data
        this.filterTreeData(this.treeData)
        generateList(this.treeData);
      }).catch(error => {
        console.log(error)
      })
    },
    loadBtnData () {
      getDetailList(this.selWikiDicId).then(res => {
        this.form.resetFields();
        this.wikiDic = res.data;
        this.content = this.wikiDic.content;
        document.getElementById("contentDiv").innerHTML = this.wikiDic.content;
        this.isUpdate = false;
      }).catch(error => {
        console.log(error)
      })
    },
    handleCancel(e) {
      this.visible = false;
    },
    // fromType:0我的知识库，1公共知识库，2我的收藏
    init(record, fromType){
      this.visible = true

      this.dicName = record.wikiName
      this.wikiId = record.id
      this.fromType = fromType
      this.loadTreeData()
    }
  }
}
</script>

<style lang="less">
.wrapper {
  height: 100%;
  margin: 0 !important;
}

.tree-node-title {
  display: inline-block;
  margin-right: 8px;
}

.tree-node-handle {
  .anticon {
    margin: 4px;
  }
}
</style>