<template>
  <a-modal title="分类列表" :visible="visible" :width="750" :confirm-loading="confirmLoading" @cancel="handleCancel">
    <a-button type="primary" id="load" @click="createFormEvt">新建</a-button>
    <div class="page-search-wrap"  style="margin-top:10px">
        <s-table ref="table" size="default" rowKey="id" :data="loadData" :columns="columns" :pageNum="pageNum"
          :pageSize="5" :pagination="{pageSizeOptions:['5']}">

          <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>

          <template slot="action" slot-scope="text,record">
            <a href="javascript:void(0)" class="table-operation-action" @click="updateFormEvt(record)">编辑</a>
            <a-popconfirm title="确定删除此数据么？" ok-text="确定" cancel-text="取消" placement="topLeft"
              @confirm="delWikiTypeEntListEvt(record)">
              <a href="javascript:void(0)" class="table-operation-action">删除</a>
            </a-popconfirm>
          </template>
        </s-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <a-button @click="visible = false">关闭</a-button>
    </span>

  <!-- 新建弹窗 -->
  <add-form v-if="addFormVisible" ref="addForm" @refreshDataList="$refs.table.refresh(true)"></add-form>
  <!-- 修改弹窗 -->
  <update-form v-if="updateFormVisible" ref="updateForm" @refreshDataList="$refs.table.refresh(true)"></update-form>

  </a-modal>
</template>
<script>
import { delWikiTypeEntList, getWikiTypeEntList } from "@/httpsAPI/knowledgeBase/index"
import AddForm from './add'
import UpdateForm from './update'

const columns = [
  {
    title: '序号',
    scopedSlots: { customRender: 'serial' }
  },
  {
    title: "分类名称",
    dataIndex: "name"
  },
  {
    title: "操作",
    dataIndex: "action",
    align: 'center',
    scopedSlots: { customRender: "action" },
    width: "188px"
  }
];

export default {
  data () {
    return {
      addFormVisible:false,
      updateFormVisible:false,
      columns,
      queryParams: {},
      pageNum: 1,
      visible: false,
      confirmLoading: false,
      loadData: parameter => {
        this.pageNum = parameter.pageNo;
        return getWikiTypeEntList(Object.assign(parameter, this.queryParams))
          .then(res => {
            return res.data
          })
      }
    }
  },
  components: {
    AddForm,
    UpdateForm
  },
  methods: {
    //删除
    delWikiTypeEntListEvt (item) {
      const that = this
      delWikiTypeEntList([item.id]).then(res => {
        that.$message.success({
          content: '操作成功',
          duration: 1,
          onClose: () => {
            this.$refs.table.refresh(true)
          }
        })
      }).catch(error => {
        console.log(error)
      })
    },
    handleCancel (e) {
      this.visible = false;
    },        
    // 新建
    createFormEvt () {
      this.addFormVisible = true
      this.$nextTick(() => {
        this.$refs.addForm.init()
      })
    },
     // 编辑
    updateFormEvt (record) {
      this.updateFormVisible = true
      this.$nextTick(() => {
        this.$refs.updateForm.init(record)
      })
    },
    init () {
      this.visible = true;
      this.$nextTick(() => {
        this.form.resetFields();
      });
    }
  }
}
</script>